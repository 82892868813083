import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import Router from "./Routes/Router";
import queryClient from "./store";
import ToastProvider from "./context/ToastProvider"
import ToastList from "./components/Toast/ToastList";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Router />
        <ToastList />
      </ToastProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);