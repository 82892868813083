import React, { ReactNode, useLayoutEffect } from 'react';

type ITypographyTypes =
  | 'Header'
  | 'Header2'
  | 'Header3'
  | 'Body'
  | 'Body2'
  | 'Body3';

interface TypographyProps {
  children: ReactNode;
  type: ITypographyTypes;
  typeSM?: ITypographyTypes;
  className?: string;
  onClick?: () => void;
}

const getBaseClasses = () => `
    font-medium
    text-dark-grey
`;

const getClassesByType = (
  type: ITypographyTypes,
  typeSM?: ITypographyTypes,
  isMobile?: boolean,
) => {
  if (isMobile && typeSM) {
    return returnClassNames(typeSM);
  }
  return returnClassNames(type);
};

const returnClassNames = (type: ITypographyTypes) => {
  switch (type) {
    case 'Header':
      return `
        ${getBaseClasses()}
          text-5xl
          font-medium
          tracking-[-0.48px]
        `;
    case 'Header2':
      return `
            ${getBaseClasses()}
              text-2xl
              font-medium
              leading-10
              tracking-[-0.40px]
                `;
    case 'Header3':
      return `
                ${getBaseClasses()}
                  text-xl
                  font-medium
                  tracking-[-0.32px]
                        `;
    case 'Body':
      return `
                  text-20
                  font-medium
                  leading-8
                  tracking-[-0.32px]
                    ${getBaseClasses()}
        `;
    case 'Body2':
      return `
                  text-base
                  leading-6
                  font-medium
                  tracking-[-0.20px]
                        ${getBaseClasses()}
            `;

    case 'Body3':
      return `
                  text-xs
                  font-medium
                  leading-4
                  tracking-[-0.16px]
                            ${getBaseClasses()}
                `;

    default:
      return getBaseClasses();
  }
};

const Typography: React.FC<TypographyProps> = ({
  children,
  type,
  typeSM,
  className,
  onClick,
}) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  useLayoutEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    }
    // Initial dimensions
    handleResize();
    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <p
      onClick={onClick}
      className={`  ${getClassesByType(type, typeSM, isMobile)} ${
        className || ''
      }`}>
      {children}
    </p>
  );
};

export default Typography;
