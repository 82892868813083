import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axiosInterceptor } from '..'; 
import { IRecoveryRequest, IResponse } from '../interface'; 


type IUseRecoveryRequest = UseMutationOptions<
    IResponse<null>, 
    AxiosError<IResponse<string>>, 
    IRecoveryRequest
>;

const useRecoveryRequest = (options?: IUseRecoveryRequest) => {
    const mutation = useMutation({
        ...options,
        mutationKey: ['recovery-request'], 
        mutationFn: async (data: IRecoveryRequest) => {
            return (
                await axiosInterceptor.post(
                    'b2b-admins/recovery-request',
                    data
                )
            ).data;
        },
        onSuccess: async (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
        },
        onError: (error, variables, context) => {
            options?.onError?.(error, variables, context);
        },
    });

    return mutation;
};

export default useRecoveryRequest;