import React, {  useRef, useState } from 'react'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden'
import Header from '../components/Header/Header'
import GridComponent from '../components/GridComponent/GridComponent'
import { LeftSideComponent } from '../components/Sidebar/Sidebar'
import GridTitle from '../components/GridComponent/components/GridTitle'
import { useNavigate } from 'react-router-dom'
import Typography from '../components/Typography/Typography';
import { Form, Formik, FormikProps } from 'formik'
import InputField from '../components/InputField/InputField'
import Button from '../components/Button/Button'
import useSingleOrganisation from '../store/Querys/useSingleOrganization'
import useOrganisation from '../store/Querys/useOrganisation'
import useUpdateOrganization from '../store/Mutations/useUpdateOrganization'
import { useToast } from '../context/ToastProvider'
import { OrganizationDetailsSchema } from '../scemas/validations'

const AccountDetails = () => {

  return (
    <div className='flex flex-col lg:flex-row bg-[#F5F5FC] lg:bg-transparent h-svh lg:h-auto'>
      <GridLGHidden>
        <Header backgroundColor='[#F5F5FC]'/>
      </GridLGHidden>  
      <GridComponent
          leftSideComponent={
            <LeftSideComponent/>
          }
          containerClassNames={
            'lg:grid-cols-grid-chat-no-right-lg'
          }
          mainComponent={<MainComponent />}
         mainComponentContainerClassNames="!overflow-hidden"
        />
    </div>
  )
}

export default AccountDetails;

const MainComponent = () => {
    const navigate = useNavigate();
    // const [countriesList, setCountriesList] = useState<Country[]>([]);
    // const [stateList, setStateList] = useState<State[]>([]);
    // const [selectedCountryId, setSelectedCountryId] = useState<string | null>(null);

    const {data: organizationsData} = useOrganisation();
    const organizationId = organizationsData?.organizations?.[0]?.id ;
    const { data } = useSingleOrganisation(organizationId);
    const { addToast } = useToast();
  
    const {mutate: updateOrganizationMutation, isPending} = useUpdateOrganization(organizationId, {
      onSuccess: () => {
        addToast({
          message: 'Organization updated successfully',
          type: 'success',
        });
      },
      onError: (error) => {
        addToast({
          message: 'Failed to update organization: ' + error.message,
          type: 'error',
        });
      },
    });

    // useEffect(() => {
    //   setCountriesList(countries);
    // }, []);
  
    // useEffect(() => {
    //   if (selectedCountryId === "233") { 
    //     setStateList(states);
    //   } else {
    //     setStateList([]); 
    //   }
    // }, [selectedCountryId]);
  
    // const countryOptions = countriesList.map(country => ({
    //   value: String(country.id),
    //   label: country.name,
    // }));
  
    // const stateOptions = stateList.map(state => ({
    //   value: state.state_code,
    //   label: state.name,
    // }));
    const formikRef = useRef<FormikProps<any>>(null);
    const [isFormChanged, setIsFormChanged] = useState(false);
    
    return <div className='flex flex-1 flex-col lg:h-svh'>
        <GridTitle title='Organization' backBtnClick={()=> navigate("/account")}/>
          <GridLGHidden>
            <Typography type='Body2' className='text-main-gray pl-[37px] mt-8'>Manage your account details below.</Typography>
          </GridLGHidden>
        <div className='lg:bg-white rounded-20  flex-1 h-full overflow-hidden lg:pr-24 lg:py-24'>
        <div className=' lg:px-24  px-0 mt-24 lg:overflow-auto h-full pb-36'>
        {data && (
        <Formik
          innerRef={formikRef}
          initialValues={{
              organizationName: data?.organizationName || '',
              billingAddress: data?.billingAddress || '',
              country: data?.country || '',
              state: '',
              postalCode: data?.postalCode || '',
          }}
          validationSchema={OrganizationDetailsSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values) => {
            const updatedValues: Partial<typeof values> = {};
            const keysToCompare = ['organizationName', 'billingAddress', 'country', 'postalCode'] as const;         
            keysToCompare.forEach((key) => {
              if (values[key] !== data?.[key]) {
                updatedValues[key] = values[key];
              }
            });         

              updateOrganizationMutation(
                {
                  organizationName: updatedValues.organizationName,
                  postalCode: updatedValues.postalCode,
                  billingAddress: updatedValues.billingAddress,
                  country: updatedValues.country,
              });
          
          }}
        >
          {({ values, setFieldValue, handleBlur, touched, errors }) => (
            <Form className='w-full flex flex-col gap-20 lg:gap-24'>
              <InputField
                label="Organization name"
                required
                placeholder="Organization name"
                id="organization-name"
                value={values.organizationName}
                onChange={(e) => {setFieldValue('organizationName', e.target.value); setIsFormChanged(true);}}
                onBlur={handleBlur("organizationName")}
                error={
                  touched.organizationName && typeof errors.organizationName === 'string'
                    ? (errors.organizationName as string)
                    : undefined
                }
              />
              {/* <InputField
                label="Billing address"
                required
                placeholder="Billing address"
                id="billing-address"
                value={values.billingAddress}
                onChange={(e) => setFieldValue('billingAddress', e.target.value)}
              />
              <div>
                <Typography type='Body2' className='mb-7 lg:mb-24'>Country</Typography>
                <DropdownWithSearch
                    options={countryOptions}
                    value={values.country}
                    onChange={(e) => {
                      const countryId = e?.value || "";
                      setFieldValue("country", countryId);
                      setSelectedCountryId(countryId);
                    }}
                  />
              </div> */}
              {/* {selectedCountryId === "233" && (
                <div>
                <Typography type='Body2' className='mb-7 lg:mb-24'>State</Typography>
                <DropdownWithSearch
                    options={stateOptions}
                    value={values.state}
                    onChange={(e) =>
                      setFieldValue("state", e?.value)
                    }
                  />
              </div>
              )} */}
              {/* <InputField
                label="Postal code"
                required
                placeholder="Postal code"
                id="postal-code"
                value={values.postalCode}
                onChange={(e) => setFieldValue('postalCode', e.target.value)}
              />  */}
            </Form>
          )}
        </Formik>
        )}
        </div>
        </div>
        <Button buttonType='primary' title='Save' styles='my-24' type='button' loading={isPending}  disabled={!isFormChanged} onClick={() => formikRef.current?.submitForm()}/>
    </div>
}