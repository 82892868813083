import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { IResponse, ISingleOrganization} from "../interface";
import { AxiosError } from "axios";

const useSingleOrganisation = (
    organizationId: string | undefined,
    options?: Omit<
      Partial<UseQueryOptions<ISingleOrganization, AxiosError<IResponse<string>>>>,
      'queryFn'
    >,
  ) => {
    return useQuery({
      queryKey: ['single-organization', organizationId],
      queryFn: async () => {
        if (!organizationId) {
          throw new Error('Organization ID is required');
        }
        const res = await axiosInterceptor.get(`organizations/${organizationId}`);
        return res.data;
      },
      enabled: !!organizationId, 
      ...options,
    });
  };
  export default useSingleOrganisation;