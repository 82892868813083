import AuthContainer from "../components/AuthContainer/AuthContainer";
import CheckoutBox from "../components/Checkout/CheckoutBox";

const Checkout = () => {
  
  return (
    <AuthContainer>
      <CheckoutBox/>
    </AuthContainer>
  );
};

export default Checkout;
