import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import LoadingAnim from '../../assets/animations/loading.json';
import Typography from '../Typography/Typography';

const Loading = () => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  useEffect(() => {
    lottieRef?.current?.setSpeed(2);
  }, []);
  return (
    <div
      className="bg-light-grey-7 fixed left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-10"
      style={{
        zIndex: 999999,
      }}>
      <Lottie
        animationData={LoadingAnim}
        loop={true}
        style={{ width: '75px', height: '75px' }}
        lottieRef={lottieRef}
      />
      <Typography type="Body2" className="text-white">
        Loading...
      </Typography>
    </div>
  );
};

export default Loading;
export { Loading };
