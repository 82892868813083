import { BrowserRouter, Routes } from "react-router-dom";
import { useEffect } from "react";
import useOrganisation from "../store/Querys/useOrganisation";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "../store/keys";
import AuthRoutes from "./AuthRoutes";
import useMaintenance from "../store/Querys/useMaintenance";

const Router = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const queryClient = useQueryClient();
  const enabled = accessToken !== null;
  const { data: maintenanceData, isLoading } = useMaintenance();
  const maintenanceB2B = maintenanceData?.webB2BMaintenance;

  useOrganisation({
    enabled: enabled,
  });

  useEffect(() => {
    if (!accessToken) {
      queryClient.removeQueries({
        queryKey: [QueryKeys.Organisation],
      });
    }
  }, [accessToken, queryClient]);

  useEffect(() => {
    const isMaintenancePage = window.location.pathname === "/maintenance";
    if (maintenanceB2B && !isMaintenancePage) {
      window.location.href = "/maintenance";
    }
    // also checking isLaoding because if data isnt in cache yet, we dont want to redirect from maintenance page
    if (isMaintenancePage && !maintenanceB2B && !isLoading) {
      window.location.href = "/";
    }
  }, [maintenanceB2B]);

  return (
    <BrowserRouter>
      <AuthRoutes />
      <Routes>{/* <Route path="*" element={<>404</>} /> */}</Routes>
    </BrowserRouter>
  );
};

export default Router;
