import React, { useRef, useState } from 'react';
import EyeIcon from '../../assets/icons/bx-show.svg';
import EyeGray from '../../assets/icons/bx-show-gray.svg';
import Typography from '../Typography/Typography';

type Props = {
  label?: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'checkbox' | 'date';
  placeholder?: string;
  direction?: 'row' | 'col';
  customInputStyles?: string;
  labelStyles?: string;
  passwordWithIcon?: boolean;
  passwordText?: string;
  value?: string | number;
  error?: string;
  id?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputField: React.FC<Props> = ({
  label,
  type = 'text',
  placeholder = '',
  direction = 'col',
  customInputStyles,
  labelStyles,
  passwordWithIcon,
  passwordText,
  value,
  error,
  id,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'email' && event.key === ' ') {
      event.preventDefault(); // Prevent space from being entered in email field
    }
  };

  return (
    <div
      className={`flex w-full ${
        direction === 'row' ? 'flex-row items-center' : 'flex-col'
      }`}>
      {label && (
        <label
          className={`${
            direction === 'col' ? 'mb-12 lg:mb-16' : 'mr-20'
          } text-dark-grey text-base font-medium   ${labelStyles}`}>
          {label}
        </label>
      )}
      <div className="relative w-full">
        <input
          type={showPassword ? 'text' : type}
          className={`shadow-custom-blue lg:shadow-none border-light-grey-3 text-dark-grey focus:border-primary w-full rounded-2xl border border-solid bg-white px-10 text-base font-medium focus:outline-none lg:px-16 py-16 ${customInputStyles} ${
            error ? '!border-red' : ''
          } `}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          ref={inputRef}
          value={value}
          id={`${id}-input`}
          {...rest}
        />
        {passwordWithIcon && (
          <div className="absolute right-16 top-1/2 flex -translate-y-1/2 items-center gap-12">
            {passwordText && (
              <Typography type="Body2" className="text-xs">
                {passwordText}
              </Typography>
            )}
            <img
              src={!value ? EyeGray : EyeIcon}
              alt=""
              className="cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        )}
      </div>
      {error && (
        <Typography type="Body2" className="text-red mt-[8px] text-[12px]">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default InputField;
