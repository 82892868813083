import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axiosInterceptor } from '..';
import { IResponse } from '../interface';

interface UpdateAdminData {
  firstName?: string;
  lastName?: string;
  email?: string;
  active?: boolean;
}

type IUseUpdateAdmin = UseMutationOptions<
  IResponse<null>,
  AxiosError<IResponse<string>>,
  UpdateAdminData
>;

const useUpdateAdmin = (options?: IUseUpdateAdmin) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    ...options,
    mutationKey: ['update-admin'],
    mutationFn: async (data: UpdateAdminData) => {
      return (await axiosInterceptor.put('/b2b-admins/update/me', data)).data;
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({ queryKey: ['organisation'] })
    },
    onError: (error, variables, context) => {
      options?.onError?.(error, variables, context);
    },
  });

  return mutation;
};

export default useUpdateAdmin;
