import React, { useEffect, useRef, useState } from "react";
import AmericanExpress from "../../assets/images/amex.svg";
import Apple from "../../assets/images/apple-pay.svg";
import Mastercard from "../../assets/images/mastercard.svg";
import Visa from "../../assets/images/visa.svg";
import { Link } from "react-router-dom";
import { PackageNames } from "../../store/interface";
import useProcessGateway from "../../store/Mutations/organisation-useProcessPayment";
import GridLGHidden from "../GridComponent/components/GridLGHidden";
import Header from "../Header/Header";
import Typography from "../Typography/Typography";
import InputField from "../InputField/InputField";
import Divider from "../Divider/Divider";
import PickPlanItem from "../PickPlanItem/PickPlanItem";
import Button from "../Button/Button";
import LockIcon from "../../assets/icons/bx-lock-gray.svg";
import TextWithBackButton from "../TextWithBackButton";
import useUpdateOrganization from "../../store/Mutations/useUpdateOrganization";
import useOrganisation from "../../store/Querys/useOrganisation";


let cardsData = [
  {
    planID: "Biennial Subscription" as PackageNames,
    planName: "Premium 24 Months",
    premiumYearly: true,
    premium3Monthly: false,
    planPrice: "119.99",
    planNewPrice: "$710.99",
    planOldPrice: "$1143.88",
    discount: "50",
    monthCount: 24,
    pricePerMonth: "4.99"
  },
  {
    planID: "Yearly Subscription" as PackageNames,
    planName: "Premium 12 Months",
    premiumYearly: true,
    premium3Monthly: false,
    planPrice: "71.99",
    planNewPrice: "$1079.9",
    planOldPrice: "$1143.88",
    discount: "27",
    monthCount: 12,
    pricePerMonth: "5.99"
  },
  {
    planID: "Quarterly Subscription" as PackageNames,
    planName: "Premium 3 Months",
    premiumYearly: true,
    premium3Monthly: true,
    planPrice: "29.99",
    planNewPrice: "$449.9",
    planOldPrice: "",
    discount: "",
    monthCount: 3,
    pricePerMonth: "9.99"
  },
];

const CheckoutBox = ({backBtnClick, oldOrganizationName, orgId}:{backBtnClick?: ()=> void; oldOrganizationName?: string; orgId?: string;}) => {
  const [selectedPlan, setSelectedPlan] = useState<PackageNames>(
    "Biennial Subscription"
  );

  const organisationID = window.localStorage.getItem("checkoutOrganisationID") || orgId || "";
  const [selectedLicenses, setSelectedLicenses] = useState(10);
  const [organizationName, setOrganizationName] = useState("");
  const [inputValue, setInputValue] = useState<string>("10");
  const [error, setError] = useState(false);
  const [orgNameError, setOrgNameError] = useState(false);

  const { mutate, isPending } = useProcessGateway({
    onSuccess: (data) => {
      window.location.href = data.sessionUrl;
      window.localStorage.setItem(
        "selectedPlan",
        selectedPlan
      );
    },
  });

  const {mutate: updateOrganizationMutation} = useUpdateOrganization(organisationID);

  // useEffect(() => {
  //   return () => {
  //     window.localStorage.removeItem("checkoutOrganisationID");
  //     window.localStorage.removeItem("checkoutOrganisationLicense");
  //   };
  // }, []);

  // if (organisationID === null || organisationLicenseNumber === null) {
  //   alert("Problem with organisation ID or organisation license number");
  //   navigate("/login");
  // }

  return (
      <div className="lg:rounded-20 lg:max-h-90% flex w-full lg:w-[522px] flex-col overflow-hidden bg-[#FBFBFE] lg:bg-white relative">
        <div className="overflow-auto  lg:my-24 lg:box-border lg:h-full pb-200 lg:pb-186 h-svh">
          <GridLGHidden>
            <Header backgroundColor="[#FBFBFE]" />
          </GridLGHidden>
          <div className="px-12 lg:px-0 lg:mx-24">
            {backBtnClick ? 
            <TextWithBackButton
            text="Pick a plan"
            backBtnClick={backBtnClick}
            /> :
            <Typography type="Header3" className="mb-32">
              Pick a plan
            </Typography>
            }
            {
                backBtnClick && 
                <div className="my-24">
                    <InputField
                    customInputStyles="!border-light-grey-10"
                label="Organization name"
                required
                placeholder="Organization name"
                id="organization-name"
                value={organizationName}
                onChange={(e) => {
                  setOrganizationName(
                    e.target.value
                  )
                  !e.target.value ?  setOrgNameError(true) : setOrgNameError(false);
                }}                 
              />

            {orgNameError && (
               <Typography type="Body2" className="text-talklap-red mt-2 ml-4">
                Required 
                </Typography>
            )}
                </div>                
            }

            <InputField
              customInputStyles="!border-light-grey-10"
              type="number"
              label="How many licenses would you like to purchase ?"
              required
              placeholder="10"
              min={10}
              onKeyDown={(e) => {
                if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let value = e.target.value.trim();
                value = value.replace(/[^0-9]/g, '');
                if (value !== "") {
                  value = String(parseInt(value, 10)); 
                }
                setInputValue(value);              
                const numericValue = Number(value);             
                if (!isNaN(numericValue)) {
                  setSelectedLicenses(numericValue);
                }            
                if (numericValue > 9 && numericValue < 999) {
                  setError(false);
                } 
              }}
              value={inputValue}
            />
            {error && (
               <Typography type="Body2" className="text-talklap-red mt-2 ml-4">
               {selectedLicenses > 999 ? `License count cannot be more than 999.`: "Minimum 10 licenses required"}
             </Typography>
            )}
            <Divider className="h-[2px] !bg-light-grey-10" />
            <div className=" lg:mt-32 flex flex-col gap-20 lg:gap-24">
              {cardsData.map((item) => (
                <PickPlanItem
                  key={item.planName}
                  planID={item.planID}
                  planName={item.planName}
                  planPrice={item.planPrice}
                  premium3Monthly={item.premium3Monthly}
                  premiumYearly={item.premiumYearly}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={(e: PackageNames) => {
                    setSelectedPlan(e);
                  }}
                  selectedLicenses={selectedLicenses}
                  threeMonthPlanPrice={29.99}
                  monthCount={item.monthCount}
                  pricePerMonth={item.pricePerMonth}
                />
              ))}

              <Divider
                marginY="my-0"
                marginX="lg:mx-0 mx-24"
                className="!bg-[#E3E5FA] !h-[2px]"
              />
              <div className="flex flex-col gap-12">
                <label
                  className={`border-light-grey-10 focus:outline-primary rounded-2xl  flex cursor-pointer  items-center gap-12  border-[2px] p-12 py-16`}
                >
                  <input
                    type="radio"
                    value="card"
                    checked={true}
                    id="radio-btn-card"
                    name="payment-method"
                  />
                  <Typography type="Body2">Card</Typography>
                  <img src={Visa} alt="" />
                  <img src={Mastercard} alt="" />
                  <img src={AmericanExpress} alt="" />
                  <img src={Apple} alt="" />
                </label>
              </div>
              <div>
                <Typography type="Body3" className="text-main-gray">
                  By picking a plan, you accept our{" "}
                  <Link
                    to={"https://talkpal.ai/terms-and-conditions/"}
                    target="_blank"
                    className="underline underline-offset-2"
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and {" "}
                  <Link
                    to={"https://talkpal.ai/privacy-policy/"}
                    target="_blank"
                    className="underline underline-offset-2 "
                  >
                    Privacy Policy.
                  </Link>{" "}
                  Occasionally, we’ll send you our newsletters, with learning tips and special offers. You can unsubscribe anytime. *Compared to 3 Months plan.
                </Typography>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 bg-[#FBFBFE] lg:bg-white flex items-center flex-col gap-20 w-full px-24 pb-20">
              <div className="px-12 lg:px-0 w-full">
                <Divider marginY="my-0" className="w-full" />
              </div>
              <div className="flex gap-4">
                {cardsData.find((item) => item.planID === selectedPlan)
                  ?.planOldPrice && (
                  <Typography
                    type="Body2"
                    className="text-main-gray line-through decoration-[#EA2D71]"
                  >
                    ${Math.round(Number(cardsData.find((item) => item.planID === "Quarterly Subscription")?.planPrice) * selectedLicenses * (Number(cardsData.find((item) => item.planID === selectedPlan)?.monthCount)/3) * 100)/100}{" "}
                  </Typography>
                )}
                {selectedPlan !== "BasicPlan" && (
                  <Typography type="Body2">
                    ${Math.round(Number(cardsData.find((item) => item.planID === selectedPlan)?.planPrice) * selectedLicenses * 100)/100}{" "}
                    (billed every {cardsData.find((item) => item.planID === selectedPlan)?.monthCount} months)
                  </Typography>
                )}
              </div>
              <Button
                title="Continue"
                buttonType="primary"
                loading={isPending}
                onClick={() => {
                  if(selectedLicenses < 10 || selectedLicenses > 999){
                    setError(true);
                  }else{
                    if(!error){
                      mutate({
                        packageName: selectedPlan,
                        organizationId: organisationID || "",
                        licenseCount: +selectedLicenses
                      });
                      if(backBtnClick && organizationName && organizationName !== oldOrganizationName){
                        updateOrganizationMutation(
                          {
                            organizationName: organizationName,
                        });
                      }
                    }
                  }
                }}
              />
              <Typography
                type="Body3"
                className="text-main-gray flex items-center gap-4"
              >
                <img src={LockIcon} alt="" className="w-16 h-16" />
                Cancel anytime
              </Typography>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CheckoutBox;
