import React, { useLayoutEffect } from 'react'
import LicensesBox from '../components/StudentsComponents/LicensesBox'
import { useNavigate } from 'react-router-dom'
import TextWithBackButton from '../components/TextWithBackButton';
import Header from '../components/Header/Header';

const Licenses = () => {
    const navigate = useNavigate();

    useLayoutEffect(() => {
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      metaThemeColor && metaThemeColor.setAttribute('content', '#F5F5FC');
    }, []);

  return (
    <div className='h-svh flex flex-col bg-[#F5F5FC] pb-80'>
        <Header backgroundColor='[#F5F5FC]'/>
        <div className='px-12'>
            <TextWithBackButton text='Licenses' subText='Current amount of licenses.' backBtnClick={()=> navigate("/students")}/> 
        </div>
        <LicensesBox onInviteBtnClick={()=> navigate("/students/invite")} />
    </div>
  )
}

export default Licenses
