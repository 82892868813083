import React from "react";
import Typography from "../Typography/Typography";
import { PackageNames } from "../../store/interface";

interface PickPlanItemProps {
  planID: PackageNames;
  planName: string;
  premiumYearly?: boolean;
  premium3Monthly?: boolean;
  planPrice?: string;
  selectedPlan: string;
  setSelectedPlan: (val: PackageNames) => void;
  selectedLicenses: number;
  threeMonthPlanPrice: number;
  monthCount: number;
  pricePerMonth: string;
}

const PickPlanItem = ({
  planID,
  planName,
  premiumYearly,
  premium3Monthly,
  planPrice,
  selectedPlan,
  setSelectedPlan,
  selectedLicenses,
  threeMonthPlanPrice,
  monthCount,
  pricePerMonth
}: PickPlanItemProps) => {

  const getDiscount = ()=>{
    const newPricee = Number(planPrice) * selectedLicenses;
    const oldPrice = threeMonthPlanPrice  * selectedLicenses * (monthCount/3);
    return   Math.round((1-newPricee/oldPrice)*100) 
  }

  return (
    <div
      className={`rounded-20   ${
        selectedPlan === planID ? "bg-border-gradient p-4" : "bg-light-grey-10 p-[2px]"
      }`}
    >
      <div
        onClick={() => {
          setSelectedPlan(planID);
        }}
        className={`bg-white cursor-pointer p-16 lg:p-24 pr-12 lg:pr-16 ${
          selectedPlan === planID ? "rounded-2xl" : "rounded-[18px]"
        } flex justify-between relative`}
      >
        {(!premium3Monthly) && (
          <div className="absolute right-24 lg:right-24 -top-[12.5px] h-[25px] px-8 bg-[#EA2D71] text-white text-xs rounded-20 !font-semibold flex items-center">
            SAVE {getDiscount()}%*
          </div>
        )}

        <Typography type="Body2">{planName}</Typography>

        <div className="text-end">
          <Typography type="Body2" className="!font-bold">
            ${pricePerMonth}
          </Typography>
          <Typography type="Body3" className="text-main-gray font-bold">
            month / license{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PickPlanItem;
