import { useDimensions } from '../../lib/useDimensions';
import { motion } from 'framer-motion';
import React from 'react';

type Props = {
  leftSideComponent: React.ReactNode;
  mainComponent?: React.ReactNode;
  rightSideComponent?: React.ReactNode;

  leftSideClassNames?: string;
  rightSideClassNames?: string;
  mainComponentClassNames?: string;
  mainComponentContainerClassNames?: string;
  containerClassNames?: string;
  children?: React.ReactNode;
  dropdownSettings?: string;
  setDropdownSettings?: (val: string) => void;
};

const GridComponent = ({
  rightSideClassNames = '',
  mainComponentClassNames = '',
  leftSideComponent,
  rightSideComponent,
  mainComponent,
  leftSideClassNames = '',
  containerClassNames = '',
  mainComponentContainerClassNames = '',
  children,
  dropdownSettings,
  setDropdownSettings
}: Props) => {
  const isMobile = useDimensions();
  
  return (
    <div
      className={` lg:grid-cols-grid-dashboard-lg grid lg:h-full w-full grid-cols-1 justify-center lg:shadow transition-all duration-500 ${containerClassNames} `}>
      {dropdownSettings && (
        <div
          onClick={() => {
            setDropdownSettings?.('');
          }}
          className=" 
          lg:bg-light-grey-7 fixed bottom-0 left-0 right-0 top-0 z-40 flex h-svh w-full items-center justify-center px-44"
        />
      )}
      <aside
        className={
          'outline-light-grey-10 outline-[1px] z-10  hidden max-h-screen rounded  bg-white outline  lg:block' +
          leftSideClassNames
        }>
        {leftSideComponent}
      </aside>

      <main
        className={
          ` ${
             isMobile ? '' : ' outline'
          } outline-light-grey-10  outline-[1px]  relative flex flex-1 overflow-x-hidden
           overflow-y-hidden lg:h-svh  lg:bg-[#F7F7FD] ${dropdownSettings ? "z-50": ""} ` +
          mainComponentClassNames
        }>
        <motion.div
          className={`hide-scrollbar relative flex h-full w-full transform items-center
          overflow-y-hidden transition-transform duration-1000 lg:absolute lg:top-0 ${
         'translate-x-0'
          }`}>
          <div
            className={`hide-scrollbar flex h-full flex-1 overflow-y-auto p-12 pt-0 lg:h-svh lg:pb-24 lg:px-24 ${mainComponentContainerClassNames}`}>
            {children || mainComponent}
          </div>
        </motion.div>
      </main>
      {rightSideComponent  && (
        <aside
          className={
            'outline-light-grey-10 hidden max-h-screen bg-white  p-12 pt-0 outline lg:block lg:px-24 ' +
            rightSideClassNames
          }>
            <>{rightSideComponent}</>
        </aside>
      )}
    </div>
  );
};

export default GridComponent;
