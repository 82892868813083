

// 


import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { MutationKeys, QueryKeys } from '../keys';
import { axiosInterceptor } from '..';
import { IResponse, IFinalizeGatewayRequest, ILoginResponse } from '../interface';
type IUseFinalizePaymentOptions = UseMutationOptions<
    ILoginResponse,//IResponse
    AxiosError<IResponse<string>>,//IErrorResponse
    IFinalizeGatewayRequest
>;

const useFinalizePayment = (options?: IUseFinalizePaymentOptions) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationKey: [MutationKeys.FINALIZE_PAYMENT],
        mutationFn: async (data) => {
            return (
                await axiosInterceptor.post(
                    'b2b-payment/auth',
                    data
                )
            ).data;
        },
        ...options,
        onSuccess: async (res, res1, res2) => {
            await queryClient.setQueryData([QueryKeys.Organisation], res.b2bAdmin);
            window.localStorage.setItem('accessToken', res.token.accessToken)
            window.localStorage.setItem('refreshToken', res.token.refreshToken)
            options?.onSuccess?.(res, res1, res2);
        },
    });

    return mutation;
};
export default useFinalizePayment;
