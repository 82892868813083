import React from 'react'
import AuthContainer from '../components/AuthContainer/AuthContainer'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden';
import Header from '../components/Header/Header';
import Typography from '../components/Typography/Typography';
import Image from "../assets/images/img-girl-1.svg";
import Divider from '../components/Divider/Divider';
import Button from '../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <AuthContainer>
      <div className="lg:rounded-20 flex min-h-svh flex-col bg-[#FBFBFE] bg-gray-50 lg:min-h-fit lg:w-[391px] lg:bg-white lg:p-20">
        <GridLGHidden>
          <Header backgroundColor='[#FBFBFE]'/>
        </GridLGHidden>
        <Typography type='Header3' className='mb-12 px-12'>Welcome to Talkpal</Typography>
        <Typography type='Body2' className='text-main-gray px-12'>Get started by inviting students to your organization from your dashboard.</Typography>
        <div className="lg:mt-55 absolute bottom-24 left-0 flex w-full flex-col items-center px-12 lg:relative lg:bottom-0">
          <img
            src={Image}
            alt=""
            width={280}
            className="!h-228 !w-228 xs:!w-280 xs:!h-auto object-cover"
          />
          <Divider margin="m-24" className="mb-0 mt-0 w-full relative lg:top-[-2px]" />
          <Button
            buttonType="primary"
            styles="mt-12"
            title={"Get started"}
            onClick={()=>{
              navigate("/students")
            }}
          />
        </div>
      </div>
    </AuthContainer>
  )
}

export default Welcome
