import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import Typography from '../Typography/Typography'
import Button from '../Button/Button'
import XIcon from "../../assets/icons/bx-x-gray.svg";
import GridSMHidden from '../GridComponent/components/GridSMHidden';
import useSendInvitations from '../../store/Mutations/useSendInvitation';
import useOrganisation from '../../store/Querys/useOrganisation';
import { useToast } from '../../context/ToastProvider';
import ErrorIcon from "../../assets/icons/bx-error-brown.svg";
import CloseIcon from "../../assets/icons/bx-x-brown.svg";
import useSingleOrganisation from '../../store/Querys/useSingleOrganization';
import useInvitedStudents from '../../store/Querys/useInvitations';

const InviteStudentsBox = ({
  onSuccess,
  cancelClick,
}: {
  onSuccess?: () => void;
  cancelClick?: () => void;
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [notEnoughLicenses, setNotEnoughLicenses] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); 

    const { addToast } = useToast(); 

  const {mutate: sendInvitationsMutation, isPending} = useSendInvitations({
    onSuccess: () => {
      addToast({
        message: "Invitation has been sent successfully.",
        headerText: 'Invitation sent',
        type: 'success',
      });
      setEmails([]);
    },
    onError: (error) => {
      console.log(error)
      addToast({
        message: 'Failed to send invitations: ' + error?.response?.data.message[0],
        type: 'error',
      });
    },
  });

    const {data: organizationsData} = useOrganisation();
    const orgId = organizationsData?.organizations?.[0]?.id || "";

    const {data: singleOrganizationData, refetch} = useSingleOrganisation(orgId);

  const { data: students } = useInvitedStudents(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    if(singleOrganizationData?.totalLicenses === singleOrganizationData?.usedLicenses){
      setNotEnoughLicenses(true);
    }

    
    if (input.includes(",")) {
      const newEmails = input.split(",").map((email) => email.trim());
      setEmails([...emails, ...newEmails]);
      setInputValue("");
      refetch();
    } else {
      setInputValue(input);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "," || e.key === "Enter") {
      refetch();
      const email = inputValue.trim();
      if (email && validateEmail(email)) {
        setEmails([...emails, email]);
        setInputValue("");
      }
      e.preventDefault();
    } else if (e.key === "Backspace" && inputValue === "") {
      if (emails.length > 0) {
        setEmails(emails.slice(0, -1));
      }
      e.preventDefault();
    }
  };

  useEffect(() => {
    const isValidEmail = inputValue.trim() !== "" && validateEmail(inputValue);
    setIsButtonEnabled(emails.length > 0 || isValidEmail);
  }, [emails, inputValue]);

  useEffect(() => {
    if (
      singleOrganizationData &&
      emails.length + (inputValue ? 1 : 0) >
        singleOrganizationData?.totalLicenses -
          singleOrganizationData?.usedLicenses
    ) {
      setNotEnoughLicenses(true);
    } else {
      setNotEnoughLicenses(false);
    }
  }, [emails, singleOrganizationData, inputValue]);

  const removeEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleInviteClick = () => {
    refetch();
    const email = inputValue.trim();
    const allEmails = email ? [...emails, email] : emails;

    const lowercaseEmails = allEmails.map((e) => e.toLowerCase());
    const existingEmails = students?.map((student) => student.email.toLowerCase()) || [];
    
    const duplicateEmails = lowercaseEmails.filter((email) => existingEmails.includes(email));
    const uniqueEmailsToSend = lowercaseEmails.filter((email) => !duplicateEmails.includes(email));
  
    if (duplicateEmails.length > 0) {
      addToast({
        message: `The following emails are already invited: ${duplicateEmails.join(', ')}`,
        type: 'error',
      });
    }
  
    if (uniqueEmailsToSend.length > 0 && orgId && !notEnoughLicenses) {
      sendInvitationsMutation(
        {
          emails: Array.from(new Set(uniqueEmailsToSend)), 
          orgId,
        },
        {
          onSuccess: () => {
            setInputValue(''); 
            setEmails([]); 
            refetch();
            if (onSuccess) {
              onSuccess();
            }
          },
        }
      );
    }
  };
  
  return (
    <div className="lg:border-light-grey-10 rounded-20 lg:border px-12 lg:p-24 mb-24 flex-1 relative w-full">
      <GridSMHidden>
        <Typography type="Header3">Invite students</Typography>
        <Typography type="Body2" className="text-main-gray mt-24">
          Email, comma separated.
        </Typography>
      </GridSMHidden>
      <div className={`border border-light-grey-10 rounded-20 px-12 py-18 mt-24 bg-white lg:bg-transparent overflow-auto ${notEnoughLicenses ? "max-h-[17svh] lg:max-h-[30svh]" : "max-h-[36svh] lg:max-h-[40svh]"}`}>
        <div className="flex flex-wrap gap-12">
          {emails.map((email, index) => (
            <div
              key={index}
              className="flex items-center  rounded-20 border border-light-grey-10 h-50 px-8 bg-[#F7F7FD]"
            >
              <Typography type="Body3" className="text-main-gray">
                {email}
              </Typography>
              <img
                src={XIcon}
                alt=""
                onClick={() => removeEmail(index)}
                className="cursor-pointer"
              />
            </div>
          ))}
          <input
            className="focus:outline-none  ml-12 flex-grow font-medium placeholder-main-gray"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type or paste emails here"
          />
        </div>
      </div>
      {notEnoughLicenses && (
        <div className="bg-[#FBE5B6] rounded-20 p-16 flex gap-16 mt-24 items-center relative">
          {/* <img
            src={CloseIcon}
            alt=""
            className="cursor-pointer absolute top-16 right-16"
          /> */}
          <div className="bg-[#F6BC2F] rounded-2xl flex justify-center items-center w-40 h-40">
            <img src={ErrorIcon} alt="" />
          </div>
          <div>
            <Typography type="Header3" className="!text-[#6B4100]">
              Not enough licenses
            </Typography>
            <Typography
              type="Body3"
              className="!text-[#6B4100] opacity-50 mt-[6px] max-w-[70%]"
            >
              The number of invites exceeds the amount of unused licenses.
            </Typography>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-14 absolute bottom-24 left-0 lg:px-24 px-12 w-full">
        <Button
          title="Invite"
          buttonType="primary"
          loading={isPending}
          onClick={handleInviteClick}
          disabled={!isButtonEnabled || isPending || notEnoughLicenses} // Disable based on the button state
        />
        <Button
          title="Cancel"
          buttonType="outline"
          styles="!text-primary"
          onClick={cancelClick}
        />
      </div>
    </div>
  );
};

export default InviteStudentsBox;
