import React from 'react'
import StudentsIcon from "../../assets/icons/bx-user-check.svg";
import StudentsIconBlue from "../../assets/icons/bx-user-check-blue.svg";
import StatisticsIcon from '../../assets/icons/bx-trending-up-black.svg';
import StatisticsIconBlue from '../../assets/icons/bx-trending-up-blue.svg';
import AccountIcon from '../../assets/icons/bx-user.svg';
import AccountBlue from '../../assets/icons/bx-user-blue.svg';
import { useLocation, useNavigate } from 'react-router-dom';

const BottomNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  return (
    <div className='h-80 w-full flex items-center justify-center gap-80 bg-[#FBFBFE] fixed bottom-0'>
      <div className={`rounded-full ${currentPath.startsWith("/students") ? "bg-white shadow-custom-blue" : ""} w-40 h-40 flex justify-center items-center`} onClick={()=>navigate("/students")}>
        <img src={currentPath.startsWith("/students") ? StudentsIconBlue : StudentsIcon} alt="" />
      </div>
      <div className={`rounded-full ${currentPath.startsWith("/statistics") ? "bg-white shadow-custom-blue" : ""} w-40 h-40 flex justify-center items-center`} onClick={()=>navigate("/statistics")}>
        <img src={currentPath.startsWith("/statistics") ? StatisticsIconBlue : StatisticsIcon} alt="" />
      </div>
      <div className={`rounded-full ${currentPath.startsWith("/account") ? "bg-white shadow-custom-blue" : ""} w-40 h-40 flex justify-center items-center`} onClick={()=>navigate("/account")}>
        <img src={currentPath.startsWith("/account") ? AccountBlue : AccountIcon} alt="" />
      </div>
    </div>
  )
}

export default BottomNav
