import React, { useEffect, useRef } from 'react';
import {Sheet} from 'react-modal-sheet';
import './BottomSheetModal.css';

interface BottomSheetModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  setOpen: (arg: boolean) => void;
  darkBackground?: boolean;
  blackBackground?: boolean;
}

const BottomSheetModal = ({
  children,
  isOpen,
  onClose,
  setOpen,
  darkBackground,
  blackBackground,
}: BottomSheetModalProps) => {
  const modalWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (window.window.innerWidth >= 1024) return;
      if (
        modalWrapperRef.current &&
        !modalWrapperRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        setOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setOpen]);

  return (
    // HIDING THE MODAL ON LARGE SCREENS

    <Sheet
      className="!z-[9999] lg:invisible lg:hidden"
      isOpen={isOpen}
      onClose={onClose}>
      <div className="z-50 lg:-z-50">
        <Sheet.Container
          ref={modalWrapperRef}
          style={{
            backgroundColor: darkBackground
              ? '#EAEBF9 !important'
              : blackBackground
              ? '#000 !important'
              : '#fff',
          }}
          className={
            darkBackground
              ? '!bg-secondary'
              : blackBackground
              ? '!bg-black'
              : '!bg-white'
          }>
          <Sheet.Header />
          <Sheet.Content>{children}</Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </div>
    </Sheet>
  );
};

export default BottomSheetModal;
