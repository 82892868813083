import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { IResponse, IStats} from "../interface";
import { AxiosError } from "axios";

const useProgression = (
    organizationId: string | undefined,
    options?: Omit<
      Partial<UseQueryOptions<IStats, AxiosError<IResponse<string>>>>,
      'queryFn'
    >,
  ) => {
    return useQuery({
      queryKey: ['progression', organizationId],
      queryFn: async () => {
        if (!organizationId) {
          throw new Error('Organization ID is required');
        }
        const res = await axiosInterceptor.get(`progression-b2b/${organizationId}/stats`);
        return res.data;
      },
      enabled: !!organizationId, 
      ...options,
    });
  };
  export default useProgression;