import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IToast {
  id: string;
  headerText?: string;
  message: string;
  type: 'error' | 'success' | 'warning' | 'network';
  autoPlay?: boolean;
}

const ToastContext = createContext<{
  toasts: IToast[];
  addToast: (toast: Omit<IToast, 'id'>) => void;
  clearToast: (id: string) => void;
}>({
  toasts: [],
  addToast: () => {},
  clearToast: () => {},
});

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = (toast: Omit<IToast, 'id'>) => {
    const id = uuidv4();
    setToasts((prev) => [...prev, { ...toast, id }]);
  };

  const clearToast = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ toasts, addToast, clearToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};

export default ToastProvider;