import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be at least 8 characters'),
});

const SignUpSchema = Yup.object().shape({
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('organization.b2bAdmin.password'), undefined], 'Passwords must match')
    .required('Required')
    .min(8, 'Must be at least 8 characters'),
  organization: Yup.object().shape({
    organizationName: Yup.string().required('Required'),
    // postalCode: Yup.string().required('Postal code is required'),
    // billingAddress: Yup.string().required('Billing address is required'),
    // totalLicenses: Yup.number().required('Number of licenses is required'),
    // country: Yup.string().required('Country is required'),
    // city: Yup.string().required('City is required'),
    b2bAdmin: Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Required')
      .min(8, 'Must be at least 8 characters'),
    }),
  }),
});

export const subscriptionCreate = Yup.object().shape({
  organization: Yup.object().shape({
    organizationName: Yup.string().required('Required'),
    // postalCode: Yup.string().required('Postal code is required'),
    // billingAddress: Yup.string().required('Billing address is required'),
    // totalLicenses: Yup.number().required('Number of licenses is required'),
    // country: Yup.string().required('Country is required'),
    // city: Yup.string().required('City is required'),
  }),
});




const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Required')
    .trim(),
});

const CreateNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be at least 8 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
});


const AccountDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
});

const OrganizationDetailsSchema = Yup.object().shape({
  organizationName: Yup.string().required('Required'),
});

export {
  LoginSchema,
  SignUpSchema,
  ResetPasswordSchema,
  CreateNewPasswordSchema,
  AccountDetailsSchema,
  OrganizationDetailsSchema
};
