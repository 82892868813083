import React, { useLayoutEffect, useRef, useState } from 'react'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden'
import Header from '../components/Header/Header'
import GridComponent from '../components/GridComponent/GridComponent'
import { LeftSideComponent } from '../components/Sidebar/Sidebar'
import GridTitle from '../components/GridComponent/components/GridTitle'
import { useNavigate } from 'react-router-dom'
import Typography from '../components/Typography/Typography';
import { Form, Formik, FormikProps } from 'formik'
import InputField from '../components/InputField/InputField'
import Button from '../components/Button/Button'
import useOrganisation from '../store/Querys/useOrganisation'
import useUpdateAdmin from '../store/Mutations/useUpdateAdmin';
import { useToast } from '../context/ToastProvider';
import { AccountDetailsSchema } from '../scemas/validations'

const OrganizationDetails = () => {

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#F5F5FC');
  }, []);

  return (
    <div className='flex flex-col lg:flex-row bg-[#F5F5FC] lg:bg-transparent'>
      <GridLGHidden>
        <Header backgroundColor='[#F5F5FC]'/>
      </GridLGHidden>  
      <GridComponent
          leftSideComponent={
            <LeftSideComponent/>
          }
          containerClassNames={
            'lg:grid-cols-grid-chat-no-right-lg'
          }
          mainComponent={<MainComponent />}
         mainComponentContainerClassNames="!overflow-hidden"
        />
    </div>
  )
}

export default OrganizationDetails;

const MainComponent = () => {
  const navigate = useNavigate();
  const { data: organizationsData } = useOrganisation();
  const { addToast } = useToast();

  const {mutate: updateAdminMutation, isPending} = useUpdateAdmin({
    onSuccess: () => {
      addToast({
        message: 'Updated successfully',
        type: 'success',
      });
    },
    onError: (error) => {
      addToast({
        message: 'Failed to update: ' + error.message,
        type: 'error',
      });
    },
  });

  const formikRef = useRef<FormikProps<any>>(null);
  const [isFormChanged, setIsFormChanged] = useState(false);

  return (
    <div className='flex flex-1 flex-col lg:h-svh h-svh pb-[80px] lg:pb-0'>
      <GridTitle title='Account details' backBtnClick={() => navigate("/account")} />
      <GridLGHidden>
        <Typography type='Body2' className='text-main-gray pl-[37px] mt-8'>
          Manage your account details below.
        </Typography>
      </GridLGHidden>
      <div className='lg:bg-white rounded-20  flex-1 h-full overflow-hidden lg:pr-24 lg:py-24'>
        <div className='lg:px-24 px-0 mt-24 lg:overflow-auto h-full pb-36'>
        {organizationsData && (
          <Formik
            innerRef={formikRef}
            initialValues={{
              firstName: organizationsData?.firstName || '',
              lastName: organizationsData?.lastName || '',
              email: organizationsData?.email || '',
            }}
            validationSchema={AccountDetailsSchema}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={(values) => {
              updateAdminMutation({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                active: true,
              });
            }}
          >
            {({ values, setFieldValue, handleBlur, touched, errors }) => (
              <Form className='w-full flex flex-col gap-20 lg:gap-24'>
                <InputField
                  label="First name"
                  required
                  placeholder="First name"
                  id="first-name"
                  value={values.firstName}
                  onChange={(e) => {setFieldValue('firstName', e.target.value); setIsFormChanged(true);}}
                  onBlur={handleBlur("firstName")}
                  error={
                    touched.firstName && typeof errors.firstName === 'string'
                      ? (errors.firstName as string)
                      : undefined
                  }
                />
                <InputField
                  label="Last name"
                  required
                  placeholder="Last name"
                  id="last-name"
                  value={values.lastName}
                  onChange={(e) => {setFieldValue('lastName', e.target.value); setIsFormChanged(true);}}
                  onBlur={handleBlur("lastName")}
                  error={
                    touched.lastName && typeof errors.lastName === 'string'
                      ? (errors.lastName as string)
                      : undefined
                  }
                />
                <InputField
                  label="Email"
                  required
                  placeholder="Email"
                  id="email"
                  disabled
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  customInputStyles='bg-[#F8F8F8] text-main-gray'
                />
              </Form>
            )}
          </Formik>
        )}
        </div>
      </div>
      <Button
        buttonType='primary'
        title='Save'
        styles='my-24'
        type='button'
        loading={isPending}
        onClick={() => formikRef.current?.submitForm()}
        disabled={!isFormChanged}
      />
    </div>
  );
};

