import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

const Authenticate = () => {
  const params = useSearchParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    const accessToken = params[0].get("accessToken");
    const refreshToken = params[0].get("refreshToken");
    const nextURL = params[0].get("nextURL");
    if (!accessToken || !refreshToken || !nextURL) {
      window.location.href = "/login";
    }
    window.localStorage.setItem("accessToken", accessToken || "");
    window.localStorage.setItem("refreshToken", refreshToken || "");
    queryClient.removeQueries();
    window.location.href = nextURL || "/account";
  }, []);

  return <Loading />;
};

export default Authenticate;
