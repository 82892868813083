export const MutationKeys = {
    LOGIN: 'Login',
    FINALIZE_PAYMENT: 'FinalizePayment',
    PROCESS_GATEWAY: 'ProcessGateway',
    CREATE_ORGANISATION_WITH_ADMIN: 'CreateOrganisationWithAdmin',
    SEND_INVITATIONS: "SendIvitation"
}

export const QueryKeys = {
    Organisation: 'Organisation',
    INVITED_STUDENTS: "invited-students",
    MAINTENANCE: 'Maintenance',
}