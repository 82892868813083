import { useEffect, useState } from 'react';

export function useDimensions() {
  const [isMobile, setMobile] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setMobile(true);
        return;
      }
      setMobile(false);
    }
    // Initial dimensions
    handleResize();
    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}
