import React from 'react'
import GridComponent from '../components/GridComponent/GridComponent';
import { LeftSideComponent } from '../components/Sidebar/Sidebar';
import GridTitle from '../components/GridComponent/components/GridTitle';
import Typography from '../components/Typography/Typography';
import TimerIcon from "../assets/icons/bx-stopwatch-red.svg";
import TimeIcon from "../assets/icons/bx-time-purple.svg";
import ProfileIcon from "../assets/icons/bx-user-brown.svg";
import GridLGHidden from '../components/GridComponent/components/GridLGHidden';
import Divider from '../components/Divider/Divider';
import GridSMHidden from '../components/GridComponent/components/GridSMHidden';
import Header from '../components/Header/Header';
import BottomNav from '../components/BottomNav/BottomNav';
import useOrganisation from '../store/Querys/useOrganisation';
import useProgression from '../store/Querys/useProgression';
import { IStats } from '../store/interface';


const Statistics = () => {
    const { data: adminData } = useOrganisation();
    const organizationId = adminData?.organizations?.[0]?.id ;
    const { data: statsData } = useProgression(organizationId);
    

  return (
    <div className='flex flex-col lg:flex-row bg-[#F5F5FC]'>
      <GridLGHidden>
        <Header backgroundColor='[#F5F5FC]'/>
      </GridLGHidden>  
      <GridComponent
          leftSideComponent={
            <LeftSideComponent/>
          }
          containerClassNames={
            'lg:grid-cols-grid-chat-no-right-lg'
          }
          mainComponent={<MainComponent statsData={statsData}/>}
        //  mainComponentContainerClassNames="max-w-[1083px]"
        />
        <GridLGHidden>
            <BottomNav/>
        </GridLGHidden>
    </div>
  )
}

export default Statistics;

const MainComponent = ({statsData} : {statsData?: IStats}) => {


    return <div className='flex flex-1 flex-col h-svh bg-[#F5F5FC] lg:bg-transparent'>
        <GridTitle title='Statistics'/>
        <GridLGHidden>
            <Typography type='Body2' className='text-main-gray mt-8 mb-20'>Overview of students data.</Typography>
        </GridLGHidden>
        <div className='bg-white p-24 rounded-20 border border-light-grey-10'>
            <GridSMHidden>
                <Typography type='Header3'>Overview</Typography>
                <Typography type='Body2' className='text-main-gray mt-12'>Overview of students average activity.</Typography>
            </GridSMHidden>
            <div className='lg:mt-48 lg:grid lg:grid-cols-2 gap-[49px]'>
                <div className='flex gap-12 items-center'>
                    <div className='bg-[#FFE1DE] rounded-2xl w-40 h-40 lg:w-60 lg:h-60 flex justify-center items-center'>
                        <img src={TimerIcon} alt="" className='w-24 h-24 lg:w-36 lg:h-36' />
                    </div>
                    <div>
                        <Typography type='Body' typeSM='Body2'>
                            {`${
                              statsData?.totalPracticeTime.days && statsData?.totalPracticeTime.days !== 0
                                ? `${statsData?.totalPracticeTime.days} days, `
                                : ''
                            }${
                                statsData?.totalPracticeTime.hours && statsData?.totalPracticeTime.hours !== 0
                                ? `${statsData?.totalPracticeTime.hours} hours, `
                                : ''
                            }${statsData?.totalPracticeTime.minutes || "0"} minutes`}
                        </Typography>
                        <Typography type='Body2' typeSM='Body3' className='text-main-gray mt-4'>Total practice time.</Typography>
                    </div>
                </div>
                <GridLGHidden className='w-full'>
                    <Divider marginY='my-16'/>
                </GridLGHidden>
                <div className='flex gap-12 items-center'>
                    <div className='bg-[#F2E3FF] rounded-2xl w-40 h-40 lg:w-60 lg:h-60 flex justify-center items-center'>
                        <img src={TimeIcon} alt="" className='w-24 h-24 lg:w-36 lg:h-36' />
                    </div>
                    <div>
                        <Typography type='Body' typeSM='Body2' >
                            {`${
                              statsData?.avgPracticeTime.days && statsData?.avgPracticeTime.days !== 0
                                ? `${statsData?.avgPracticeTime.days} days, `
                                : ''
                            }${
                                statsData?.avgPracticeTime.hours && statsData?.avgPracticeTime.hours !== 0
                                ? `${statsData?.avgPracticeTime.hours} hours, `
                                : ''
                            }${statsData?.avgPracticeTime.minutes || "0"} minutes`}
                        </Typography>
                        <Typography type='Body2' typeSM='Body3' className='text-main-gray mt-4'>Average practice time.</Typography>
                    </div>
                </div>
                <GridLGHidden className='w-full'>
                    <Divider marginY='my-16'/>
                </GridLGHidden>
                <div className='flex gap-12 items-center'>
                    <div className='bg-[#FBE5B6] rounded-2xl w-40 h-40 lg:w-60 lg:h-60 flex justify-center items-center'>
                        <img src={ProfileIcon} alt="" className='w-24 h-24 lg:w-36 lg:h-36' />
                    </div>
                    <div>
                        <Typography type='Body' typeSM='Body2'>{statsData?.activeSubUsers || "0"} {statsData && statsData?.activeSubUsers === 1 ? "student" : "students"}</Typography>
                        <Typography type='Body2' typeSM='Body3' className='text-main-gray mt-4'>Total of currently active students.</Typography>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
