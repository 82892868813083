import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../keys';
import { axiosInterceptor } from '..';
import { ILoginResponse, IResponse } from '../interface';
export interface ILoginCredentials {
    email: string;
    password: string;
    remember: boolean;
}
type IUseLogin = UseMutationOptions<
    ILoginResponse,//IResponse
    AxiosError<IResponse<string>>,//IErrorResponse
    ILoginCredentials
>;

const useLogin = (options?: IUseLogin) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        ...options,
        mutationKey: [MutationKeys.LOGIN],
        mutationFn: async (data: ILoginCredentials) => {
            return (
                await axiosInterceptor.post(
                    'b2b-admins/login',
                    {
                        ...data,
                        email: data.email?.toLowerCase(),
                    },
                )
            ).data;
        },
        onSuccess: async (res, res1, res2) => {
            await queryClient.setQueryData([QueryKeys.Organisation], res.b2bAdmin);
            window.localStorage.setItem('accessToken', res.token.accessToken)
            window.localStorage.setItem('refreshToken', res.token.refreshToken)
            options?.onSuccess?.(res, res1, res2);
        },
    });

    return mutation;
};
export default useLogin;
