import LogoSvg from '../../assets/icons/BlueLogo.svg';
import { Link } from 'react-router-dom';
import EditIcon from "../../assets/icons/bx-edit.svg";
import EditIconBlue from "../../assets/icons/bx-edit-blue.svg";
import GridLGHidden from '../GridComponent/components/GridLGHidden';
import Typography from '../Typography/Typography';
interface HeaderProps {
  backgroundColor?: string;
  editIcon?: boolean;
  editIconClick?: ()=> void;
  editable?: boolean;
  handleSelectAll?: ()=> void;
  allStudentsSelected?: boolean;
}

const Header = ({
  backgroundColor,
  editIcon,
  editIconClick,
  handleSelectAll,
  editable,
  allStudentsSelected
}: HeaderProps) => {

  return (
    <div
      className={`px-18 sticky top-0 flex h-80 w-full items-center justify-between lg:relative lg:bg-white lg:px-24 ${
        backgroundColor ? 'bg-' + backgroundColor : ' bg-light-grey-5'
      } z-10`}>
      <Link to={'/students'}>
        <img src={LogoSvg} width={28} height={40} alt="" />
      </Link>
      {editIcon && <GridLGHidden>
        <div className='flex items-center gap-24'>
          {editable && <Typography type='Body2' className='cursor-pointer' onClick={handleSelectAll}>{allStudentsSelected ? "Unselect all":"Select all"}</Typography>}
          <div className='w-40 h-40 flex justify-center items-center cursor-pointer' onClick={editIconClick}>
            <img src={editable ? EditIconBlue : EditIcon} alt=""/>
          </div>
        </div></GridLGHidden>}
    </div>
  );
};

export default Header;
