import { Outlet } from "react-router-dom";
// import useOrganisation from "../store/Querys/useOrganisation";
// import Loading from "../components/Loading/Loading";

export const UnProtectedRoute = () => {
  // const accessToken = window.localStorage.getItem("accessToken");
  // const enabled = accessToken !== null;
  // const { data, isPending } = useOrganisation({
  //   enabled: accessToken !== null,
  // });
  return  (
    <>
      {/* {isPending && enabled ? <Loading /> : null} */}
      <Outlet />
    </>
  ) 
};
