import GridSMHidden from "../GridComponent/components/GridSMHidden";

const DropdownMenu = ({
  children,
  dropdownComponent,
  dropdownEnabled,
  dropdownInnerContainerClassNames,
  setDropdownEnabled,
}: {
  children: React.ReactNode;
  dropdownComponent: React.ReactNode;
  dropdownEnabled: boolean;
  setDropdownEnabled: (val: boolean) => void;
  dropdownInnerContainerClassNames?: string;
}) => {
  const toggleDropdown = () => {
    setDropdownEnabled(!dropdownEnabled);
  };

  return (
    <div className="animate-pulseOnce relative inline-block text-left z-50">
      <div className="grid place-items-center">
        <button
          onClick={e => {
            e.stopPropagation();
            toggleDropdown();
          }}
          type="button"
          className="inline-flex  w-full bg-transparent "
          id="menu-button"
          aria-expanded={dropdownEnabled}
          aria-haspopup="true">
          {children}
        </button>
      </div>
      <GridSMHidden>
        {dropdownEnabled && (
          <div
            className={` absolute -right-8 mt-4 origin-top-right rounded-2xl rounded-tr-none bg-white p-16  shadow-lg focus:outline-none ${
              dropdownInnerContainerClassNames
                ? dropdownInnerContainerClassNames
                : ''
            }`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button">
            <div
              className="relative py-1"
              role="none"
              onClick={() => {
                toggleDropdown();
              }}>
              <div className="absolute -right-16 bottom-[108%] -z-10 h-40 w-40 rounded-t-lg bg-white"></div>
              {dropdownComponent}
            </div>
          </div>
        )}
      </GridSMHidden>
    </div>
  );
};

export default DropdownMenu;
