import React from 'react'
import AuthContainer from '../components/AuthContainer/AuthContainer'
import TextWithBackButton from '../components/TextWithBackButton'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import InputField from '../components/InputField/InputField'
import Button from '../components/Button/Button'
import Divider from '../components/Divider/Divider'
import Typography from '../components/Typography/Typography'
import GridLGHidden from '../components/GridComponent/components/GridLGHidden'
import Header from '../components/Header/Header'
import useRecoveryRequest from '../store/Mutations/useRecoveryRequest '
import { ResetPasswordSchema } from '../scemas/validations'

const ResetPassword = () => {
    const navigate = useNavigate();

    const { mutate: sendRecoveryRequest, isPending } = useRecoveryRequest();

  return (
    <AuthContainer>
      <div className="w-full bg-light-grey-5 lg:rounded-20 lg:min-h-360 relative flex min-h-svh flex-col lg:bg-white px-12  lg:p-24">
        <GridLGHidden>
            <Header/>
        </GridLGHidden>
        <TextWithBackButton text="Reset password" backBtnClick={()=> navigate('/login')} subText='Enter the email associated with your account and we’ll send an email with instructions to reset password.'/>
      <Formik
          initialValues={{ email: '' }}
          validationSchema={ResetPasswordSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => {
            sendRecoveryRequest({
                email: values.email,
                source: 'web'
            });
            navigate(`/check-email?email=${encodeURIComponent(values.email)}`);
        }}
        >
          {({ values, setFieldValue, handleBlur, touched, errors }) => (
            <Form className="flex flex-1 flex-col justify-between gap-72 mt-16 lg:mt-72">
                <InputField
                label={"Your email address"}
                required
                placeholder={"Your email address"}
                type="email"
                id="resetPassword-email"
                value={values.email}
                onChange={(e) => setFieldValue('email', e.target.value)}
                onBlur={handleBlur("email")}
                error={
                  touched.email &&
                  errors.email
                    ? errors.email
                    : ""
                }
              />

                <Button
                buttonType="primary"
                type="submit"
                testID="send-instructions"
                title={"Send instructions"}
                loading={isPending}
              />
            </Form>)}
      </Formik>      
      <Divider/>
      <div className="mb-24 flex items-center justify-center">
        <Typography type="Body2" className="text-light-grey-4">
          Not a member yet? 
          <Link
            to="/signup"
            className="text-primary decoration-primary underline decoration-1 underline-offset-4 ml-[3px]"
            id="notaMemberYet">
            Sign up
          </Link>
        </Typography>
       </div>
      </div>
    </AuthContainer>
  )
}

export default ResetPassword
