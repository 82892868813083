
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { MutationKeys } from '../keys';
import { axiosInterceptor } from '..';
import { ICreateWithAdmin, IResponse, ICreateWithAdminResponse } from '../interface';

type IUseCreateWithAdmin = UseMutationOptions<
    ICreateWithAdminResponse,//IResponse
    AxiosError<IResponse<string>>,//IErrorResponse
    ICreateWithAdmin
>;

const useCreateWithAdmin = (options?: IUseCreateWithAdmin) => {
    const mutation = useMutation({
        mutationKey: [MutationKeys.CREATE_ORGANISATION_WITH_ADMIN],
        mutationFn: async (data) => {
            return (
                await axiosInterceptor.post(
                    'organizations/create-with-admin',
                    data
                )
            ).data;
        },
        ...options,
    });

    return mutation;
};
export default useCreateWithAdmin;
