import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
var isRefreshing = false;
const queryClient = new QueryClient({

});




export let failedQueue: {
    resolve: () => void;
    reject: (error: boolean) => void;
}[] = [];

export const processQueue = (error: boolean) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

const axiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        "source": "web",
    },
});


const useRefreshToken = async () => {
    const refreshTokenReference = window.localStorage.getItem('refreshToken');
    const data = axios.post(`${process.env.REACT_APP_API_URL}b2b-admins/refresh-token/` + refreshTokenReference, undefined, {
        headers: {
            'source': 'web'
        }
    }).then((res) => {
        return res.data;
    }).catch((err) => {
        return err.response;
    });

    return data;
}


axiosInterceptor.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInterceptor.interceptors.response.use(
    (response) => {

        return response;
    },
    async (error) => {
        const prevRequest = error.config;

        if (isRefreshing) {
            return new Promise<void>((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then(() => {
                    return axiosInterceptor(prevRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }


        // If error, process all the requests in the queue and logout the user.
        const handleError = async (error: boolean) => {
            processQueue(error);
            if (error) {
                window.localStorage.removeItem('accessToken');
                window.localStorage.removeItem('refreshToken');
                setTimeout(() => {
                    console.log('error', error);
                    window.location.href = '/';
                }, 200);
                return Promise.reject(error);
            }
            return Promise.resolve();
        };

        if (
            error?.response?.status === 401 &&
            !prevRequest?._retry &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            isRefreshing = true;
            prevRequest._retry = true;
            const getNewAccessToken = await useRefreshToken()
            if ('status' in getNewAccessToken) {
                if (getNewAccessToken.status === 401) {
                    isRefreshing = false;
                    return handleError(true);
                }
            }

            prevRequest.headers[
                'Authorization'
            ] = `Bearer ${getNewAccessToken?.token.accessToken}`;

            window.localStorage.setItem('refreshToken', getNewAccessToken.token.refreshToken);
            window.localStorage.setItem('accessToken', getNewAccessToken.token.accessToken);
            handleError(false);

            isRefreshing = false;

            return axiosInterceptor(prevRequest);
        }

        if (
            (error.response?.data.message === 'Unauthorized' ||
                error.response?.data.name === 'JsonWebTokenError' ||
                error.response?.data.name === 'Unauthorized') &&
            !isRefreshing
        ) {
            return handleError(true);
        }
        return Promise.reject(error);
    }
);




export default queryClient;
export { axiosInterceptor }
