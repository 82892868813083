import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFinalizePayment from "../../store/Mutations/organisation-useFinalizePayment";
import Loading from "../../components/Loading/Loading";
import { logCustomEvent } from "../../utils/analytics";

const Success = () => {
  const params = useSearchParams();
  const token = params[0].get("token");
  const navigate = useNavigate();
  const { mutate } = useFinalizePayment({
    onSuccess: () => {
      logCustomEvent(process.env.REACT_APP_ENV === 'prod' ? "PremiumBusinessReg(WEB)Prod" : "PremiumBusinessReg(WEB)Staging", {
        method: "Credit Card",
      });
      navigate("/welcome");
    },
  });
  useEffect(() => {
    if (token) {
      mutate({
        token,
        success: true,
      });
    }
  }, [token, mutate]);

  return <Loading />;
};

export default Success;
