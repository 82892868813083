import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axiosInterceptor } from '..';
import { IRecoveryCredentials, IResponse } from '../interface';


type IUseRecovery = UseMutationOptions<
    IResponse<string>,
    AxiosError<IResponse<string>>, 
    IRecoveryCredentials
>;

const useRecovery = (options?: IUseRecovery) => {

    const mutation = useMutation({
        ...options,
        mutationKey: ["recovery"],
        mutationFn: async (data: IRecoveryCredentials) => {
            return (
                await axiosInterceptor.post(
                    '/b2b-admins/recovery',
                    { ...data }
                )
            ).data;
        },
        onSuccess: async (res, variables, context) => {
            options?.onSuccess?.(res, variables, context);
        },
    });

    return mutation;
};

export default useRecovery;