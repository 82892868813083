import { Route, Routes } from "react-router-dom";
import StartPage from "../pages/StartPage";
import Welcome from "../pages/Welcome";
import Login from "../pages/Login";
import CheckEmail from "../pages/CheckEmail";
import Success from "../pages/Payments/Success";
import Failed from "../pages/Payments/Failed";
import { UnProtectedRoute } from "../utils/UnProtectedRoute";
import { ProtectedRoute } from "../utils/ProtectedRoute";
import Account from "../pages/Account";
import AccountDetails from "../pages/AccountDetails";
import Billing from "../pages/Billing";
import Checkout from "../pages/Checkout";
import CreateNewPassword from "../pages/CreateNewPassword";
import InviteStudents from "../pages/InviteStudents";
import Licenses from "../pages/Licenses";
import ResetPassword from "../pages/ResetPassword";
import Statistics from "../pages/Statistics";
import Students from "../pages/Students";
import OrganizationDetails from "../pages/OrganizationDetails";
import SignUp from "../pages/SignUp";
import Authenticate from "../pages/Auth/Authenticate";
import Maintenance from "../pages/Maintenance/Maintenance";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/account" element={<Account />} />
        <Route path="/account-details" element={<AccountDetails />} />
        <Route path="/organization-details" element={<OrganizationDetails />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/students/licenses" element={<Licenses />} />
        <Route path="/students/invite" element={<InviteStudents />} />
        <Route path="/checkout" element={<Checkout />} />
      </Route>
      <Route element={<UnProtectedRoute />}>
        <Route path="/students" element={<Students />} />
        <Route path="/password-reset" element={<CreateNewPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<StartPage />} />
        <Route path="/start-page" element={<StartPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/payment/cancel" element={<Failed />} />
      </Route>
      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="/maintenance" element={<Maintenance />} />
    </Routes>
  );
};

export default AuthRoutes;
