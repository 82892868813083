import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKeys } from '../keys'; 
import { axiosInterceptor } from '..';
import { IGetInvitedStudentsParams, IInvitedStudent } from '../interface';


const useInvitedStudents = (
  { orgId, status }: IGetInvitedStudentsParams,
  options?: Omit<
  Partial<UseQueryOptions<IInvitedStudent[], AxiosError<IInvitedStudent[]>>>,
  'queryFn'
>
) => {
  return useQuery({
    queryKey: [QueryKeys.INVITED_STUDENTS, orgId, status], 
    queryFn: async () => {
      const res = await axiosInterceptor.get(`/invitations/${orgId}${status ? "?" + status : ""}`)
      return res.data.invitations ?? [];
  },
    ...options,
  });
};

export default useInvitedStudents;