import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import LoadingAnim from '../../assets/animations/loading.json';
import LoadingAnimWhite from '../../assets/animations/loading-white.json';

const Loader = ({
  width,
  height,
  colorWhite,
}: {
  width: string | number;
  height: string | number;
  colorWhite?: boolean;
}) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(0.8);
    }
  }, []);

  return (
    <Lottie
      animationData={colorWhite ? LoadingAnimWhite : LoadingAnim}
      loop={true}
      style={{ width, height }}
      lottieRef={lottieRef}
    />
  );
};

export default Loader;
