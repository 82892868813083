'use client';
import StatisticsIcon from '../../assets/icons/bx-trending-up-black.svg';
import StatisticsIconBlue from '../../assets/icons/bx-trending-up-blue.svg';
import StudentsIcon from '../../assets/icons/bx-user-check.svg';
import StudentsIconBlue from '../../assets/icons/bx-user-check-blue.svg';
import Account from '../../assets/icons/bx-user.svg';
import AccountBlue from '../../assets/icons/bx-user-blue.svg';
import Header from '../../components/Header/Header';
import SideBarItem from './SideBarItem';
import { useNavigate } from 'react-router-dom';

export interface SideBarItemProps {
  title: string;
  path?: string[];
  icon?: { black: string; blue: string };
  onClick?: () => void;
  active?: boolean;
  closeProfileMenu?: () => void;
}

const Sidebar = ({
  accountClick,
  account,
}: {
  accountClick?: () => void;
  account?: boolean;
}) => {
  const NavArray: SideBarItemProps[] = [
    {
      title: "Students",
      path: ['/students'],
      icon: { black: StudentsIcon, blue: StudentsIconBlue },
    },
    {
      title: "Statistics",
      path: ['/statistics'],
      icon: { black: StatisticsIcon, blue: StatisticsIconBlue },
    },
    {
      title: "Account",
      path: ['/account'],
      icon: { black: Account, blue: AccountBlue },
    },
  ];

  const navigate = useNavigate();


  return (
    <div className="flex flex-1 flex-col px-24 mt-56">
      <div className="flex flex-col justify-around py-4">
        <div className="gap-30 flex flex-col ">
          {NavArray.map((item, index) => (
            <SideBarItem
              key={index}
              title={item.title}
              icon={item.icon}
              path={item.path}
              onClick={() => {
                navigate(item.path![0])
              }}
              active={account ? false : undefined}
            />
          ))}
        </div>      
      </div>
      {/* <SideBarItem
        title={t('Account')}
        active={account}
        onClick={accountClick}
        icon={{ black: Account, blue: AccountBlue }}
      /> */}
    </div>
  );
};

export default Sidebar;

export const LeftSideComponent = ({
  accountClick,
  account,
}: {
  accountClick?: () => void;
  account?: boolean;
}) => {
  return (
    <nav className="sticky top-0 flex h-full flex-1 flex-col overflow-y-auto pb-24 ">
      <Header backgroundColor="white" />
      <Sidebar accountClick={accountClick} account={account} />
    </nav>
  );
};
