import useUpdateOrganization from "../../store/Mutations/useUpdateOrganization";
import useOrganisation from "../../store/Querys/useOrganisation";
import CheckoutBox from "../Checkout/CheckoutBox";


const SubscriptionDetailsModal = ({
  backBtnClick,
}: {
  backBtnClick: () => void;
}) => {
    const {data: organizationsData} = useOrganisation();

  return (
    <div className="w-full h-svh bg-[#9294a4cc] flex items-center justify-center absolute top-0 left-0 z-[999999] lg:px-12">
      <CheckoutBox backBtnClick={backBtnClick} oldOrganizationName={organizationsData?.organizations?.[0]?.organizationName} orgId={organizationsData?.organizations?.[0]?.id}/>
    </div>
  );
};

export default SubscriptionDetailsModal;
