import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Typography from '../Typography/Typography';
import successIcon from '../../assets/icons/bx-check.svg';
import errorIcon from '../../assets/icons/bx-error-circle.svg';
import warningIcon from '../../assets/icons/bx-error.svg';
import wifiOffIcon from '../../assets/icons/bx-wifi-off.svg';
import closeIcon from '../../assets/icons/bx-x.svg';

interface IToastProps {
  id: string;  // Accept the id prop
  headerText?: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'network';
  clearToast?: () => void;
  autoPlay?: boolean;
}

const Toast: React.FC<IToastProps> = ({
  id,  // Include id in the props
  headerText,
  message,
  type,
  clearToast,
  autoPlay = true,
}) => {
  useEffect(() => {
    if (!autoPlay) return;
    const timer = setTimeout(() => {
      clearToast?.();  // Call clearToast after 5 seconds
    }, 5000);
    return () => clearTimeout(timer);
  }, [autoPlay, clearToast]);

  const getIcon = () => {
    switch (type) {
      case 'success':
        return successIcon;
      case 'error':
        return errorIcon;
      case 'warning':
        return warningIcon;
      case 'network':
        return wifiOffIcon;
      default:
        return '';
    }
  };

  return (
    <motion.div
      exit={{ opacity: 0, y: -1000, transition: { duration: 0.5 } }}
      initial={{ opacity: 0, y: -1000 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
      className={`toast toast--${type} p-16`}
      role="alert"
      style={{ zIndex: 100000 }}
    >
      <div className="toast-icon">
        <img src={getIcon()} alt={`${type} icon`} className="h-24 w-24" />
      </div>
      <div className="toast-message max-w-250 z-[999999999] break-words">
        <Typography type="Header3">{headerText}</Typography>
        <Typography type="Body3">{message}</Typography>
      </div>
      {clearToast && (
        <button className="toast-close-btn" onClick={clearToast}>
          <img src={closeIcon} alt="Close" className="h-24 w-24" />
        </button>
      )}
    </motion.div>
  );
};

export default Toast;