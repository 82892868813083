
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { MutationKeys } from '../keys';
import { axiosInterceptor } from '..';
import { IResponse, IProcessGatewayResponse, IProcessGatewayRequest } from '../interface';

type IuseProcessGateway = UseMutationOptions<
    IProcessGatewayResponse,//IResponse
    AxiosError<IResponse<string>>,//IErrorResponse
    IProcessGatewayRequest
>;

const useProcessGateway = (options?: IuseProcessGateway) => {
    const mutation = useMutation({
        mutationKey: [MutationKeys.PROCESS_GATEWAY],
        mutationFn: async (data) => {
            return (
                await axiosInterceptor.post(
                    'b2b-payment/process-subscription',
                    data
                )
            ).data;
        },
        ...options,
    });

    return mutation;
};
export default useProcessGateway;
