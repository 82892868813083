
import GridTitle from "../components/GridTitle";
import { motion } from 'framer-motion';
import {useState } from "react";
import LicensesBox from "../../StudentsComponents/LicensesBox";
import InviteStudentsBox from "../../StudentsComponents/InviteStudentsBox";
import { ISingleOrganization } from "../../../store/interface";

const RightSideComponent = ({onSuccess, singleOrganizationData, refetch}:{onSuccess?: ()=>void; singleOrganizationData?: ISingleOrganization; refetch?: ()=> void}) => {
  const [inviteStudentsView, setInviteStudentsView] = useState(false);

  return (
    <nav className="h-screen overflow-auto flex flex-col">
          <GridTitle
            title={inviteStudentsView ? "Invitation management":"Licenses"}
            backBtnClick={inviteStudentsView ? () => {setInviteStudentsView(false)} : undefined}
          />
          {inviteStudentsView ? 
          <InviteStudentsBox onSuccess={onSuccess} cancelClick={()=>setInviteStudentsView(false)}/>
          :
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <RightSideLicenseOverviewComponent
              refetch={refetch}
              singleOrganizationData={singleOrganizationData}
              onInviteBtnClick = {()=> setInviteStudentsView(true)}
            />
          </motion.div>
          }
    </nav>
  );
};

const RightSideLicenseOverviewComponent = ({
  onInviteBtnClick,
  singleOrganizationData,
  refetch
}: {
  onInviteBtnClick: () => void;
  singleOrganizationData?: ISingleOrganization;
  refetch?: () => void;
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
        <LicensesBox onInviteBtnClick={onInviteBtnClick} />
    </motion.div>
  );
};


export {
  RightSideComponent,
  RightSideLicenseOverviewComponent,
};
