import Divider from '../../Divider/Divider';
import Typography from '../../Typography/Typography';
import BackButton from '../../../assets/icons/bx-up-arrow-alt.svg';
import GridSMHidden from './GridSMHidden';

type Props = {
  title: string;
  backBtnClick?: () => void;
  className?: string;
  dividerClassName?: string;
};

const GridTitle = ({
  title,
  backBtnClick,
  className,
  dividerClassName,
}: Props) => {
  return (
    <>
      <div
        className={`flex w-full lg:py-24 `}>
        {backBtnClick && (
          <img
            src={BackButton}
            alt="back button"
            className={`-rotate-90 cursor-pointer  `}
            onClick={backBtnClick}
          />
        )}
        <Typography
          type="Header3"
          className={`${backBtnClick ? 'px-12' : ''} ` + className}>
          {title}
        </Typography>
        
      </div>
      <GridSMHidden>
        <Divider
          marginY="0"
          className={'!bg-light-grey-10 mb-24 ' + dividerClassName}
        />
      </GridSMHidden>
    </>
  );
};

export default GridTitle;
