import { Navigate, Outlet } from "react-router-dom";
import useOrganisation from "../store/Querys/useOrganisation";

export const ProtectedRoute = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const checkoutOrganisationID = window.localStorage.getItem(
    "checkoutOrganisationID"
  );
  const enabled = accessToken !== null;
  const { data, isPending } = useOrganisation({
    enabled: enabled,
    throwOnError: false,
  });

  return (isPending && enabled) || checkoutOrganisationID || data ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};
