import React, { useLayoutEffect } from 'react'
import AuthContainer from '../components/AuthContainer/AuthContainer';
import Typography from '../components/Typography/Typography';
import { ReactComponent as Image } from "../assets/images/img-girl-1.svg";
import Button from '../components/Button/Button';
import { Link } from 'react-router-dom';
import GridLGHidden from '../components/GridComponent/components/GridLGHidden';
import TextLogo from "../assets/images/talkpal-text-logo.svg";
import GridSMHidden from '../components/GridComponent/components/GridSMHidden';
import Logo from "../assets/images/Logo.svg";
import Divider from '../components/Divider/Divider';

const StartPage = () => {

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#e2e2f2');
  }, []);

  return (
    <AuthContainer>
        <div className="flex min-h-svh  lg:h-full lg:w-full items-center justify-center">
            <div className='w-full hide-scrollbar min-h-svh lg:min-h-[10%]  bg-light-blue lg:rounded-20 lg:max-h-90% relative flex h-full flex-col items-center overflow-x-hidden lg:h-auto lg:overflow-y-auto  lg:bg-white lg:p-24 lg:gap-24'>
              <GridLGHidden>
                <img
                  src={TextLogo}
                  alt=""
                  className="absolute left-16 top-20"
                />
              </GridLGHidden>
              <GridSMHidden>
                <Typography type='Header2' className='text-center mb-24'>Talkpal for organizations</Typography>
                <Typography type='Body' className='text-main-gray text-center px-36'>Provide your organization with an easy access to AI-powered digital language learning. </Typography>
              </GridSMHidden>
                <div className='flex-1 max-h-[40%] lg:h-auto flex justify-center items-end lg:flex-col lg:items-center w-full'>
                  <div className='h-5/6 lg:h-auto overflow-hidden'>
                    <Image className='h-full lg:relative lg:top-[-1px]'/>
                  </div>
                  <GridSMHidden className='w-full flex justify-center relative -top-[2px]'>
                    <Divider marginY='my-0' className='w-full max-w-300'/>
                  </GridSMHidden>
                </div>
                <div className="relative flex h-1/2 w-full grow flex-col rounded-tl-3xl rounded-tr-3xl bg-white pt-60 lg:pt-0">
          <GridLGHidden>
            <div className="bg-secondary absolute -top-40 left-1/2 z-10 flex h-80 w-80 -translate-x-1/2 transform items-center justify-center rounded-full">
              <img
                src={Logo}
                alt="logo"
                className="w-24"
              />
            </div>
          </GridLGHidden>
          <GridLGHidden className='mb-32'>
                <Typography type='Header2' className='text-center px-50 mb-12'>Talkpal for organizations</Typography>
                <Typography type='Body2' className='text-main-gray text-center px-36'>Provide your organization with an easy access to AI-powered digital language learning. </Typography>
          </GridLGHidden>
          <div className='w-full flex flex-col gap-12 px-12 lg:px-0 pb-24 lg:pb-0'>
                <Link to={'/signup'}>
                  <Button
                    buttonType="primary"
                    title={"Get started"}
                    testID="get-started"
                  />
                </Link>
                <Link to={'/login'}>
                  <Button
                    buttonType="secondary"
                    title={"Log in"}
                    testID="login-onboarding"
                  />
                </Link>
                </div>
                <GridSMHidden>
            <Divider />
          </GridSMHidden>
          <Link to={'https://talkpal.ai/'} target="_blank">
            <Typography
              type="Body2"
              className="text-main-gray mb-12 text-center underline underline-offset-4 lg:mb-0">
              {' '}
              © Talkpal, Inc.
            </Typography>
          </Link>
          </div>
            </div>
        </div>
    </AuthContainer>
  )
}

export default StartPage
