import React from 'react';
import Logo from "../../assets/icons/logo-white-transparent-bg.svg";
import GridSMHidden from '../GridComponent/components/GridSMHidden';

interface AuthContainerProps {
    children: React.ReactNode;
  }

const AuthContainer = ({children}:AuthContainerProps) => {
  return (
    <div className='flex lg:h-svh items-center justify-center lg:bg-primary'>
      <GridSMHidden>
        <img src={Logo} alt='' className='absolute left-24 top-24'/>
      </GridSMHidden>  
      <div className='lg:max-w-[700px] lg:h-svh flex items-center justify-center w-full'>
        {children}
      </div>
    </div>
  )
}

export default AuthContainer
