import { useDimensions } from '../../../lib/useDimensions';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const GridLGHidden = ({ children, className = '' }: Props) => {
  const isMobile = useDimensions();
  if (!isMobile) return null;
  return (
    <div className={`lg:hidden ${className} lg:pointer-events-none`}>
      {children}
    </div>
  );
};

export default GridLGHidden;
