import { useMutation } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { IResponse, UpdateSubscriptionData } from "../interface";
import { AxiosError } from "axios";


interface UpdateSubscriptionResponse {
    success: boolean;
}

const useUpdateSubscription = () => {
  return useMutation<IResponse<UpdateSubscriptionResponse>, AxiosError<IResponse<string>>, UpdateSubscriptionData>({
    mutationFn: async (subscriptionData: UpdateSubscriptionData) => {
      const res = await axiosInterceptor.post("b2b-payment/update-subscription", subscriptionData);
      return res.data;
    },
  });
};

export default useUpdateSubscription;