import Robot from "../../assets/images/img-robot.svg";
import Logo from "../../assets/images/Logo.svg";
import Divider from "../../components/Divider/Divider";
import Typography from "../../components/Typography/Typography";

export default function Page() {
  return (
    <div className="bg-secondary fixed inset-0 z-50 flex flex-col items-center justify-center lg:items-start">
      <img
        src={Logo}
        alt="logo"
        width={48}
        height={48}
        className="left-12 mt-16 h-40"
      />
      <div className="flex h-full w-full flex-col items-center justify-end p-6 text-center lg:justify-center">
        <div className="w-1/2">
          <img src={Robot} width={300} alt="Robot" className="mx-auto" />
          <Divider className="mt-0" />
          <Typography type="Header2">
            We’re sorry! Talkpal will return soon
          </Typography>
          <Typography type="Body2" className="text-gray">
            We are busy updating Talkpal for you. We will be back soon!
          </Typography>
        </div>
      </div>
    </div>
  );
}
