import React from 'react'
import Header from '../components/Header/Header'
import TextWithBackButton from '../components/TextWithBackButton'
import InviteStudentsBox from '../components/StudentsComponents/InviteStudentsBox'
import { useNavigate } from 'react-router-dom'
import BottomNav from '../components/BottomNav/BottomNav'

const InviteStudents = () => {
    const navigate = useNavigate();
  return (
    <div className='h-svh flex flex-col pb-56 bg-[#F5F5FC]'>
      <Header backgroundColor='[#F5F5FC]'/>
      <div className='px-12'>
      <TextWithBackButton text='Invite students' subText='Email, comma separated.' backBtnClick={()=> navigate("/students/licenses")}/>
      </div>
      <InviteStudentsBox cancelClick={()=> navigate("/students/licenses")} onSuccess={() => navigate(`/students?pending=true`)}/>
      <BottomNav/>
    </div>
  )
}

export default InviteStudents
