import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { MutationKeys } from '../keys';
import { axiosInterceptor } from '..';
import { IResponse, ISendInvitationsRequest } from '../interface';


type IUseSendInvitations = UseMutationOptions<
  IResponse<any>, 
  AxiosError<IResponse<string>>, 
  ISendInvitationsRequest 
>;

const useSendInvitations = (options?: IUseSendInvitations) => {
  const mutation = useMutation({
    ...options,
    mutationKey: [MutationKeys.SEND_INVITATIONS],
    mutationFn: async (data: ISendInvitationsRequest) => {
      return (
        await axiosInterceptor.post(
          '/invitations/send',
          {
            emails: data.emails,
            orgId: data.orgId,
          }
        )
      ).data;
    },
    onSuccess: async (res, variables, context) => {
      options?.onSuccess?.(res, variables, context);
    },
  });

  return mutation;
};

export default useSendInvitations;