import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { IResponse, UpdateOrganizationData } from "../interface";
import { AxiosError } from "axios";

const useUpdateOrganization = (
  organizationId?: string,
  options?: UseMutationOptions<IResponse<UpdateOrganizationData>, AxiosError<IResponse<string>>, UpdateOrganizationData>
) => {
  const queryClient = useQueryClient();
  
  const mutation = useMutation({
    ...options,
    mutationKey: ['update-organisation'],
    mutationFn: async (organizationData: UpdateOrganizationData) => {
      const res = await axiosInterceptor.put(`organizations/${organizationId}`, organizationData);
      return res.data.organization;
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({ queryKey: ['single-organization', organizationId] })
    },
    onError: (error, variables, context) => {
      options?.onError?.(error, variables, context);
    },
  });

  return mutation;
};

export default useUpdateOrganization;
