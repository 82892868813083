interface DividerProps {
  marginX?: string;
  marginY?: string;
  margin?: string;
  className?: string;
}

const Divider = ({ marginX, marginY, margin, className }: DividerProps) => {
  const m = margin ? margin : '';
  const mx = marginX ? marginX : '';
  const my = marginY ? marginY : 'my-24';

  return (
    <div className={`bg-light-grey-3 h-1 ${my} ${m} ${mx} ${className}`}></div>
  );
};

export default Divider;
