import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { IResponse, User } from "../interface";
import { AxiosError } from "axios";


const useOrganisation = (
    options?: Omit<
        Partial<UseQueryOptions<User, AxiosError<IResponse<string>>>>,
        'queryFn'
    >,
) => {
    return useQuery({
        queryKey: ['organisation'],
        queryFn: async () => {
            const res = await axiosInterceptor.get('b2b-admins/me');
            return res.data.b2bAdmin;
        },
        ...options,
        throwOnError: false,
    })
}
export default useOrganisation;