import React, { useLayoutEffect, useState } from "react";
import GridLGHidden from "../components/GridComponent/components/GridLGHidden";
import Header from "../components/Header/Header";
import GridComponent from "../components/GridComponent/GridComponent";
import { LeftSideComponent } from "../components/Sidebar/Sidebar";
import GridTitle from "../components/GridComponent/components/GridTitle";
import { useNavigate } from "react-router-dom";
import Typography from "../components/Typography/Typography";
import UserIcon from "../assets/icons/bx-user.svg";
import OrganizationICon from "../assets/icons/bx-group.svg";
import CardIcon from "../assets/icons/bx-credit-card.svg";
import Divider from "../components/Divider/Divider";
import LogOutIcon from "../assets/icons/bx-log-out-red.svg";
import BottomNav from "../components/BottomNav/BottomNav";
import GridSMHidden from "../components/GridComponent/components/GridSMHidden";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "../components/Loading/Loading";

const Account = () => {
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor && metaThemeColor.setAttribute('content', '#F5F5FC');
  }, []);

  return (
    <div className="flex flex-col lg:flex-row bg-[#F5F5FC] lg:bg-transparent h-svh">
      <GridLGHidden>
        <Header backgroundColor="[#F5F5FC]" />
      </GridLGHidden>
      {loading &&  <Loading/>}
      <GridComponent
        leftSideComponent={<LeftSideComponent />}
        containerClassNames={"lg:grid-cols-grid-chat-no-right-lg"}
        mainComponent={<MainComponent setLoading={setLoading}/>}
        //  mainComponentContainerClassNames="max-w-[1083px]"
      />
      <GridLGHidden>
        <BottomNav />
      </GridLGHidden>
    </div>
  );
};

export default Account;

const MainComponent = ({setLoading}:{setLoading: (val: boolean) => void}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return (
    <div className="flex flex-1 flex-col">
      <GridSMHidden>
        <GridTitle title="Account" />
      </GridSMHidden>
      <div className="lg:bg-white rounded-20 lg:p-24 ">
        <Typography type="Header3">Account Settings</Typography>
        <div className="rounded-2xl border bg-white lg:bg-transparent border-light-grey-10 p-16 mt-24">
          <div
            className="flex gap-20 items-center cursor-pointer"
            onClick={() => navigate("/account-details")}
          >
            <img src={UserIcon} alt="" />
            <div>
              <Typography type="Body" typeSM="Body2">
                Account
              </Typography>
              <Typography type="Body3" className="text-main-gray">
                Manage your account details.
              </Typography>
            </div>
          </div>
          <Divider marginY="my-16" />
          <div
            className="flex gap-20 items-center cursor-pointer"
            onClick={() => navigate("/organization-details")}
          >
            <img src={OrganizationICon} alt="" />
            <div>
              <Typography type="Body" typeSM="Body2">
                Organization
              </Typography>
              <Typography type="Body3" className="text-main-gray">
                Manage your organization details.
              </Typography>
            </div>
          </div>
        </div>
        <div className="rounded-2xl border bg-white lg:bg-transparent border-light-grey-10 p-16 mt-24">
          <div
            className="flex gap-20 items-center cursor-pointer"
            onClick={() => navigate("/billing")}
          >
            <img src={CardIcon} alt="" />
            <div>
              <Typography type="Body" typeSM="Body2">
                Billing
              </Typography>
              <Typography type="Body3" className="text-main-gray">
                Manage your subscriptions.
              </Typography>
            </div>
          </div>
          <Divider marginY="my-16" />
          <div
            className="flex gap-20 items-center cursor-pointer"
            onClick={() => {
              window.localStorage.removeItem("accessToken");
              window.localStorage.removeItem("refreshToken");
              queryClient.removeQueries();
              setLoading(true);
              setTimeout(()=>{
                setLoading(false);
                navigate("/login");
              }, 1000)
            }}
          >
            <img src={LogOutIcon} alt="" />
            <div>
              <Typography
                type="Body"
                typeSM="Body2"
                className="!text-[#EA2D71]"
              >
                Log out
              </Typography>
              <Typography type="Body3" className="text-main-gray">
                Log out from this profile.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
