import React from 'react'
import Typography from './Typography/Typography';
import BackBtn from "../assets/icons/bx-up-arrow-alt.svg";

const TextWithBackButton = ({text, backBtnClick, subText, subText2}:{text: string, backBtnClick: () => void, subText?: string, subText2?: string}) => {
  return (
    <div className='flex gap-12 items-start'>
        <img src={BackBtn} alt="" className='-rotate-90 cursor-pointer mt-[6px]' onClick={backBtnClick}/>
        <div>
          <Typography type='Header3'>{text}</Typography>
          {subText && <Typography type='Body2' className='text-main-gray mt-10 lg:mt-24 max-w-[500px]'>{subText}</Typography>}
          {subText2 && <Typography type='Body2' className='text-main-gray max-w-[500px] mt-16 lg:mt-0'>{subText2}</Typography>}
        </div>
    </div>
  )
}

export default TextWithBackButton
