import React from 'react';
import Toast from './Toast';
import './styles.css';
import { AnimatePresence } from 'framer-motion';
import { useToast } from '../../context/ToastProvider';

const ToastList: React.FC = () => {
  const { toasts, clearToast } = useToast();

  return (
    <div className={`toast-list`} aria-live="assertive" style={{ zIndex: 1000000 }}>
      <AnimatePresence>
        {toasts.map((toast:any) => (
          <Toast
            key={toast.id}
            headerText={toast.headerText}
            message={toast.message}
            type={toast.type}
            clearToast={() => clearToast(toast.id)}
            id={toast.id}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastList;