'use client';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { SideBarItemProps } from './Sidebar';
import Typography from '../Typography/Typography';

const SideBarItem = ({
  title,
  icon,
  active,
  path,
  onClick,
}: SideBarItemProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (typeof active === 'boolean') {
      setIsActive(active);
      return;
    }
    if (
      path?.some(p => window.location.pathname.includes(p))
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [active, path]);

  return (
    <motion.a
      initial={{ color: '#313131' }}
      whileHover={{ color: '#2E3BC7' }}
      animate={{ color: isActive ? '#2E3BC7' : '#313131' }}
      transition={{ duration: 0.2 }}
      onClick={() => {
        onClick?.();
      }}
      className={`flex items-center  gap-12 cursor-pointer`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {icon && (
        <img
          src={isActive || isHovered ? icon.blue : icon.black}
          alt={title}
          width={24}
          height={24}
          className="mr-5 h-24 w-24"
        />
      )}
      <Typography
        className={
          isActive || isHovered ? 'text-primary' : ' hover:text-primary'
        }
        type={title ? 'Body2' : 'Body'}>
        {title}
      </Typography>
    </motion.a>
  );
};

export default SideBarItem;
