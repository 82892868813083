import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { QueryKeys } from "../keys";

export interface IMaintenance {
    webMaintenance: boolean;
    appIosMaintenance: boolean;
    appAndroidMaintenance: boolean;
    webB2BMaintenance: boolean,

}

const useMaintenance = (
    options?: Omit<Partial<UseQueryOptions<IMaintenance>>, 'queryFn'>,
) => {

    return useQuery({
        queryKey: [QueryKeys.MAINTENANCE],
        queryFn: async () => {
            return (await axiosInterceptor.get<IMaintenance>(process.env.REACT_APP_MAINTENANCE_URL || '')).data;
        },
        ...options,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        retry: 2,
        refetchInterval: 1000 * 30, // 30 seconds
        staleTime: 1000 * 30,
    });
};

export default useMaintenance;
