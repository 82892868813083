import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { axiosInterceptor } from "..";
import { IResponse } from "../interface";
import { AxiosError } from "axios";

interface CancelSubscriptionData {
  organizationId: string;
}

const useCancelSubscription = (options?: UseMutationOptions<IResponse<any>, AxiosError<IResponse<any>>, CancelSubscriptionData>) => {
  return useMutation<IResponse<any>, AxiosError<IResponse<any>>, CancelSubscriptionData>({
    mutationFn: async (cancelSubscriptionData: CancelSubscriptionData) => {
      const res = await axiosInterceptor.post('b2b-payment/cancel-subscription', cancelSubscriptionData);
      return res.data;
    },
    ...options,
  });
};

export default useCancelSubscription;