import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axiosInterceptor } from '..';
import { IResponse } from '../interface';

interface IDeleteInvitationsRequest {
  orgId: string;
  emails: string[];
}

const deleteInvitations = async ({ orgId, emails }: IDeleteInvitationsRequest) => {
  const res = await axiosInterceptor.delete<IResponse<null>>('/invitations', {
    data: {
      orgId,
      emails,
    },
  });
  return res.data;
};

const useDeleteInvitations = (options?: UseMutationOptions<IResponse<null>, AxiosError, IDeleteInvitationsRequest>) => {
  return useMutation({
    mutationFn: deleteInvitations,
    ...options,
  });
};

export default useDeleteInvitations;