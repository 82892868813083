import { useDimensions } from '../../../lib/useDimensions';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const GridSMHidden = ({ children, className = '' }: Props) => {
  const isMobile = useDimensions();
  if (isMobile) return null;
  return <div className={`max-lg:hidden  ${className}`}>{children}</div>;
};

export default GridSMHidden;
